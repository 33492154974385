import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
  RouterLink,
} from '@angular/router';
import moment from 'moment';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable } from 'rxjs';
import { ViewContentComponent } from 'src/app/components/_layout/view-content/view-content.component';
import { ViewHeaderComponent } from 'src/app/components/_layout/view-header/view-header.component';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { DataTableService } from 'src/app/components/data-table/data-table.service';
import { OrderCardComponent } from 'src/app/components/order/order-card/order-card.component';
import { LOCAL_STORAGE_KEY } from 'src/app/config/local-storage-keys';
import { UserAction } from 'src/app/enum';
import { HttpMethod } from 'src/app/enum/http-method';
import { OrdersService } from 'src/app/services/orders.service';
import { OverlayViewService } from 'src/app/services/overlay-view.service';
import { IReactiveFormsDeepMappingOptions } from 'src/app/util/form-util';
import { StoreProductsListState } from '../../../e-store/products-list/store-products-list.card';
import { StoreState } from '../../../e-store/store.state';
import { MatTableModule } from '@angular/material/table';
import { NgClass } from '@angular/common';
import { DataTableFooterComponent } from 'src/app/components/data-table/footer/data-table-footer.component';

export const purchaseOrdersListResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(OrdersService).getSentPurchaseOrdersList();
};

@Component({
  selector: 'app-purchase-orders-view',
  templateUrl: './purchase-orders.view.html',
  styleUrls: ['./purchase-orders.view.scss'],
  standalone: true,
  imports: [
    ViewHeaderComponent,
    ViewContentComponent,
    OrderCardComponent,
    NgxSkeletonLoaderModule,
    RouterLink,
    MatTableModule,
    NgClass,
    DataTableFooterComponent,
  ],
})
export class PurchaseOrdersListComponent
  extends DataTableComponent<any, any>
  implements OnInit
{
  @ViewChild('filtersHeaderTemplate', { static: false })
  filtersHeaderTemplate?: ElementRef;
  @ViewChild('filtersContentTemplate', { static: false })
  filtersContentTemplate?: ElementRef;
  @ViewChild('filtersFooterTemplate', { static: false })
  filtersFooterTemplate?: ElementRef;

  public LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEY.PAGE_SIZE.PRODUCTS_LIST;

  @Output()
  public loaded: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public search: Observable<any>;
  private searchData: any;

  public formData: any;
  public clientTagsById: any;

  public override scrollContainerId: string = 'view';

  public override dataUrl = 'orders/purchaseOrders';
  public override method = HttpMethod.Post;
  public UserAction = UserAction;
  // public customFilter: string = this.state.listFilter;

  public columns = ['product_grid_item'];

  constructor(
    public storeState: StoreState,
    public listState: StoreProductsListState,
    route: ActivatedRoute,
    dataService: DataTableService<any, any>,
    cd: ChangeDetectorRef,
    private _ovService: OverlayViewService,
  ) {
    super(dataService, cd);
    this.initialData = route.snapshot.data['initialData'];
    // this.customFilter = this.state.listFilter;
    // this.formData = route?.snapshot?.data?.['formData'];
  }

  public override ngOnInit() {
    super.ngOnInit();

    this.loadAfterViewInit = this.search === null;

    this.dataSource.searchKey$.subscribe((x) => {
      if (x) {
        // this._location.replaceState(`/clients/${x}`);
      }
    });

    this.dataSource.loading$.subscribe((val: boolean) => {
      this.storeState.isSearchLoading = val;
    });
  }

  public get isLoading() {
    return this.storeState.isSearchLoading;
  }

  public get tableClasses() {
    return {
      'grid-table grid grid-cols-1 flex-col gap-2x md:grid-cols-2 2xl:grid-cols-2':
        true, // responsive logic here
    };
  }

  public onSearch(searchData) {
    this.searchData = searchData;
  }

  public onLoad() {
    this.load();
  }

  protected override getData() {
    return this.searchData;
  }

  public override get hasData() {
    return this.dataSource.data?.length ? true : false;
  }
}
